import * as React from "react"
import { Link, graphql } from "gatsby"
import { Disqus } from 'gatsby-plugin-disqus'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"
import ShareButtons from "../components/sharebuttons"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const breadcrumbs = [
    { name: 'Inicio', link: '/' },
    { name: post.frontmatter.title.replace(/^(.{20}[^\s]*).*/, "$1") + '...', link: location.pathname },
  ]
  let disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl + location.pathname}`,
    identifier: post.frontmatter.id,
    title: post.frontmatter.title,
  }
  let shareData = {
    title: data.markdownRemark.frontmatter.title,
    tags: data.markdownRemark.frontmatter.tag,
    url: `${data.site.siteMetadata.siteUrl + location.pathname}`,
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <div className="mb-4">
        <h1 className="h3 h4-xs font-weight-medium mb-0">
          {post.frontmatter.title}
        </h1>
        <Breadcrumbs links={breadcrumbs} />

        <p className="d-block text-muted fs--14">
          Publicado: <time dateTime="{post.frontmatter.date}">{post.frontmatter.date}</time>
        </p>
      </div>
      <div className="article-format" 
        dangerouslySetInnerHTML={{ __html: post.html }}
        itemProp="articleBody"/>

      <p className="text-muted border-bottom pb-2 fs--13 mt--60">
        Última modificación: {post.frontmatter.date}
      </p>

      <div className="row">
        <div className="col-6">
          <h4 className="fs--13 text-muted">
            Tags
          </h4>
          {post.frontmatter.tag.map(tag => {
            return (
              <button key={tag} href="#!" className="btn text-dark bg-light fs--13 rounded py-2 px-3 m-1 text-decoration-none" rel="nofollow">
                {tag}
              </button>
            )
          })}
        </div>

        <div className="col-6 col-6 text-align-end">
          <h4 className="fs--13 text-muted">
            Comparte con tus amigos
          </h4>
          <ShareButtons title={shareData.title} url={shareData.url} tags={shareData.tags} />
        </div>
      </div>

      <h3 className="h5 mt-5 mb-4">
        Entradas vecinas
      </h3>

      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          {previous && (
            <Link to={previous.fields.slug} className="d-flex p-2 mb-3 shadow-md shadow-md-hover rounded text-decoration-none transition-hover-top transition-all-ease-250" rel="prev">
              <div className="text-center bg-light w--100 h--100 rounded bg-cover lazy" 
                data-loaded="true" 
                style={{ backgroundImage: `url(${previous.frontmatter.featured_image?.replace('.jpg', 's.jpg')})`}}
              ></div>
              <div className="col px-3">
                <p className="fs--18 pt-2 mb-2 font-weight-light">
                    ← {previous.frontmatter.title}
                </p>
              </div>
            </Link>
          )}
        </div>
        <div className="col-12 col-md-6 mb-5">
          {next && (
            <Link to={next.fields.slug} className="d-flex p-2 mb-3 shadow-md shadow-md-hover rounded text-decoration-none transition-hover-top transition-all-ease-250" rel="next">
              <div className="col px-3">
                <p className="fs--18 pt-2 mb-2 font-weight-light">
                  {next.frontmatter.title} →
                </p>
              </div>
              <div className="text-center bg-light w--100 h--100 rounded bg-cover lazy"
                data-loaded="true"
                style={{ backgroundImage: `url(${next.frontmatter.featured_image.replace('.jpg', 's.jpg')})` }}
              ></div>
            </Link>
          )}
        </div>
      </div>
      
      <div className="clearfix mt-5">
        <h3 className="font-weight-light mb-4">
          Comentarios
        </h3>
        <Disqus config={disqusConfig} />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(
          formatString: "DD MMMM YYYY",
          locale: "es-MX"
        )
        description
        tag
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featured_image
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        featured_image
      }
    }
  }
`
